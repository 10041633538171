import { API } from 'constants/api'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import { ROUTE } from 'src/constants/routes'
import { Spinner } from 'capricorn-ui'
import { Template } from 'src/interfaces/Template'
import { Theme } from 'src/store/commonStore'
import { useQuery } from 'react-query'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import AccountsSvg from 'src/assets/icons/accounts.svg'
import ApiService from 'services/ApiService'
import Clock2Svg from 'src/assets/icons/clock-filled.svg'
import Exchange2Svg from 'src/assets/icons/exchange-2.svg'
import ExchangeSvg from 'src/assets/icons/exchange.svg'
import HomeSvg from 'src/assets/icons/home.svg'
import OperationsSvg from 'src/assets/icons/list.svg'
import ProfileSvg from 'src/assets/icons/profile.svg'
import React, { memo, useEffect, useState } from 'react'
import SupportSvg from 'src/assets/icons/support.svg'
import TopUpSvg from 'src/assets/icons/top-up.svg'
import TransferSvg from 'src/assets/icons/resend.svg'
import styled from 'astroturf/react'

const SidebarNav: React.FC = (props: React.HTMLProps<HTMLDivElement>) => {
    const location = useLocation()
    const intl = useIntl()
    const {
        common: { theme },
    } = useStore()
    const windowWidth = useWindowSize().width

    const [count, setCount] = useState<number>()

    useEffect(() => {
        const fetchCount = async () => {
            try {
                const count = await ApiService.request<number>('/user/help-desk/tickets/ticketsWithUnreadAnswersCount')
                setCount(count)
            } catch (e) {}
        }

        fetchCount()

        const interval = setInterval(fetchCount, 60000)

        return () => clearInterval(interval)
    }, [])

    const drafts = useQuery<{
        count: number
        list: Template[]
    }>(['drafts', 'all'], () =>
        ApiService.request(`${API.TEMPLATE_LIST}?limit=199`, { isDraft: true }).then((response) => ({
            count: response.count,
            list: response.data,
        })),
    )

    const nav =
        windowWidth > 600
            ? [
                  {
                      title: intl.formatMessage({ defaultMessage: 'My accounts' }),
                      icon: <AccountsSvg width={16} />,
                      link: ROUTE.HOME,
                  },
                  {
                      title: intl.formatMessage({ defaultMessage: 'Account top-up' }),
                      icon: <TopUpSvg width={19} />,
                      link: ROUTE.TOP_UP,
                  },
                  {
                      title: intl.formatMessage({ defaultMessage: 'Transfers' }),
                      icon: <TransferSvg width={16} />,
                      link: ROUTE.TRANSFERS_INTERNAL,
                  },
                  {
                      title: intl.formatMessage({ defaultMessage: 'History' }),
                      icon: <OperationsSvg width={16} />,
                      link: `${ROUTE.OPERATION_TRANSFERS}?account=all`,
                  },
                  {
                      title: intl.formatMessage({ defaultMessage: 'Exchange' }),
                      icon: <ExchangeSvg width={15} />,
                      link: ROUTE.EXCHANGE,
                  },
                  {
                      title: intl.formatMessage({ defaultMessage: 'Support' }),
                      icon: <SupportSvg width={16} />,
                      link: ROUTE.SUPPORT,
                      badge: count && count > 0 ? count : undefined,
                  },
              ]
            : [
                  {
                      title: intl.formatMessage({ defaultMessage: 'Main' }),
                      icon: <HomeSvg width={18} />,
                      link: ROUTE.HOME,
                  },
                  {
                      title: intl.formatMessage({ defaultMessage: 'Transfers' }),
                      icon: <TransferSvg width={18} />,
                      link: ROUTE.TRANSFERS,
                  },
                  {
                      title: intl.formatMessage({ defaultMessage: 'History' }),
                      icon: <Clock2Svg width={18} />,
                      link: ROUTE.OPERATION_TRANSFERS,
                  },
                  {
                      title: intl.formatMessage({ defaultMessage: 'Exchange' }),
                      icon: <Exchange2Svg width={16} />,
                      link: ROUTE.EXCHANGE,
                  },
                  {
                      title: intl.formatMessage({ defaultMessage: 'Profile' }),
                      icon: <ProfileSvg width={16} />,
                      link: ROUTE.MOBILE_PROFILE,
                  },
              ]

    const activeIndexLocation = nav.findIndex((item) => item.link.includes(location.pathname!.split('/')[2]))
    const activeIndexBackgroundLocation = nav.findIndex((item) =>
        item.link.includes(location.state?.backgroundLocation?.pathname.split('/')[2]),
    )

    const activeIndex = activeIndexLocation === -1 ? activeIndexBackgroundLocation : activeIndexLocation
    const sliderOffset = activeIndex && activeIndex !== -1 ? activeIndex * 60.5 + 13 + (activeIndex > 2 ? 52 : 0) : 13

    return (
        <Nav theme={theme} {...props}>
            {nav.map((item, index) => (
                <React.Fragment key={item.link}>
                    <NavItem to={item.link} active={index === activeIndex}>
                        <Icon>{item.icon}</Icon>
                        {item.title}
                        {item.badge && <Badge>{item.badge}</Badge>}
                    </NavItem>
                    {item.link === ROUTE.TRANSFERS_INTERNAL && (
                        <>
                            <SubNavItem to={ROUTE.DRAFTS} active={location.pathname === ROUTE.DRAFTS}>
                                <FormattedMessage defaultMessage="Drafts" />{' '}
                                {drafts.isLoading ? <Spinner size={10} /> : <span>({drafts.data?.count || 0})</span>}
                            </SubNavItem>
                            <SubNavItem to={ROUTE.TEMPLATES} active={location.pathname === ROUTE.TEMPLATES}>
                                <FormattedMessage defaultMessage="Templates" />
                            </SubNavItem>
                        </>
                    )}
                </React.Fragment>
            ))}
            <Slider style={{ transform: `translateY(${sliderOffset}px)` }} hidden={activeIndex === -1} />
        </Nav>
    )
}

const SubNavItem = styled(Link)<{ active?: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 89px;
    font-size: 12px;
    margin: -12px 0 18px;
    transition: 0.25s color;
    cursor: pointer;
    text-decoration: none;
    color: var(--main-third-text-color);

    &::before {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: var(--main-second-text-color);
        transition: 0.25s background;
    }

    span {
        margin-left: -5px;
        color: var(--main-text-color);
    }

    &.active,
    &:hover {
        color: #2281ff;

        &::before {
            background: #2281ff;
        }
    }

    @media (max-width: 768px) {
        padding-left: 0;
        margin-left: 0;
    }

    @media (max-width: 600px) {
        display: none;
    }
`

const NavItem = styled(Link)<{ active?: boolean }>`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    padding-left: 48px;
    margin-bottom: 15px;
    text-decoration: none;
    line-height: 1;
    color: var(--main-third-text-color);
    overflow: hidden;
    font-size: 14px;
    transition:
        0.25s color,
        0.25s background;

    &.active {
        font-weight: 600;
        color: var(--main-text-color) !important;

        svg {
            color: var(--blue-to-white) !important;
        }
    }

    &:hover {
        svg {
            color: #2281ff;
        }
    }

    @media (max-width: 768px) {
        font-size: 16px;
    }

    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        padding: 10px 0;
        height: auto;
        margin: 0;
        font-size: 11px;

        &.active {
            color: var(--blue-to-white) !important;
            font-weight: 400 !important;

            svg {
                color: var(--blue-to-white) !important;
            }
        }
    }
`

const Nav = styled.nav<{ theme: Theme }>`
    position: relative;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        align-items: center;
    }

    &.theme-light {
        ${NavItem} {
            &:hover {
                background: #f2f9fd;
            }
        }
    }

    &.theme-dark {
        ${NavItem} {
            &:hover {
                background: #2f3e5b;
            }
            svg {
                color: #2281ff;
            }
        }
    }

    @media (max-width: 600px) {
        position: fixed;
        bottom: 0;
        z-index: 99;
        flex-direction: row;
        border-top: 1px solid var(--lighter-bg-color);
        background: var(--bg-color-1);
        padding-bottom: calc(env(safe-area-inset-bottom) - 7px);

        ${NavItem}:hover {
            background: transparent !important;
        }
    }
`

const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    margin-right: 21px;

    @media (max-width: 600px) {
        margin: 0 0 7px;
    }
`

const Slider = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 18px;
    width: 2px;
    background: #2281ff;
    transition: 0.25s transform ease-in-out;

    &.hidden {
        opacity: 0;
    }

    @media (max-width: 600px) {
        display: none;
    }
`

const Badge = styled.span`
    position: relative;
    top: 1px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    height: 16px;
    padding: 0 4px;
    margin-left: 8px;
    background: #2281ff;
    color: white;
    border-radius: 8px;
    font-size: 11px;
    font-weight: 500;
    line-height: 1;

    @media (max-width: 600px) {
        position: absolute;
        top: 5px;
        right: 50%;
        transform: translateX(8px);
    }
`

export default memo(SidebarNav)
